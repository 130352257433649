import './TetrisBoard.scss';
import { defineComponent } from 'vue';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'TetrisBoard',
    props: {
        tetris: {
            type: Object,
            required: true
        },
        level: {
            type: Number,
            required: true
        },
        numRows: {
            type: Number,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    computed: {},
    methods: {
        nextBlockClass(row, col) {
            var _a;
            return 'color-' + (((_a = this.tetris.nextBlock.shape[row]) === null || _a === void 0 ? void 0 : _a[col]) ? 'black' : 'white');
        },
        matrixClass(row, col) {
            if (this.tetris.getColor(row, col) !== null)
                return 'color-' + this.tetris.getColor(row, col);
            else
                return '';
        }
    }
});
