import { defineComponent } from 'vue';
import useMySchedule from '@/compositions/useMySchedule';
import useMyTeam from '@/compositions/useMyTeam';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'Postgame',
    props: {
        successhtml: {
            type: String,
            required: true
        },
        failhtml: {
            type: String,
            required: false,
            default: ''
        },
        success: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const { team } = useMyTeam();
        return {
            ...useMySchedule('dekluis', team),
            ...useTranslations()
        };
    },
    computed: {
        score() {
            return this.$store.direct.state.rooms.score;
        }
    },
    mounted() {
        if (this.success) {
            new Audio(require('@/assets/sound/success.mp3')).play();
        }
    }
});
