export const possibleBlocks = {
    long: [
        [false, true, false, false],
        [false, true, false, false],
        [false, true, false, false],
        [false, true, false, false]
    ],
    square: [
        [false, false, false, false],
        [false, true, true, false],
        [false, true, true, false],
        [false, false, false, false]
    ],
    Lleft: [
        [false, false, true, false],
        [false, false, true, false],
        [false, true, true, false],
        [false, false, false, false]
    ],
    Lright: [
        [false, true, false, false],
        [false, true, false, false],
        [false, true, true, false],
        [false, false, false, false]
    ],
    piramide: [
        [false, true, false, false],
        [false, true, true, false],
        [false, true, false, false],
        [false, false, false, false]
    ],
    zleft: [
        [false, false, false, false],
        [false, false, true, true],
        [false, true, true, false],
        [false, false, false, false]
    ],
    zright: [
        [false, false, false, false],
        [false, true, true, false],
        [false, false, true, true],
        [false, false, false, false]
    ],
};
export class TetrisBlock {
    constructor() {
        this.shape = this.createRandomBlock();
    }
    createRandomBlock() {
        const randomBlockKey = Object.keys(possibleBlocks)[Math.floor(Math.random() * Object.keys(possibleBlocks).length)];
        // @ts-ignore
        return possibleBlocks[randomBlockKey];
    }
    rotated() {
        const rotatedBlock = new TetrisBlock();
        // @ts-ignore
        rotatedBlock.shape = this.shape.map((_, colIndex) => this.shape.map(row => row[colIndex]).reverse());
        return rotatedBlock;
    }
}
