import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { IntroductionSlide, Timer, Level, MessageBar, FullscreenMessage } from '@/components/Widgets';
import TetrisGame from './Tetris/TetrisGame.vue';
import './RoomArcade.scss';
import { Animation } from '@/Animation';
import { GameType } from '@/types/rooms';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'RoomArcade',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        TetrisGame,
        Level,
        MessageBar,
        IntroductionSlide,
        FullscreenMessage
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data() {
        return {
            running: false
        };
    },
    computed: {
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        levelInfo() {
            var _a;
            const status = this.$store.direct.state.rooms.status[this.roomid];
            if ((status === null || status === void 0 ? void 0 : status.type) === GameType.Arcade) {
                return { ...status.level_info, level: (_a = status.level) !== null && _a !== void 0 ? _a : 0 };
            }
            return undefined;
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gameState === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            const tetris = this.$refs.tetris;
            if (eventType === 'gamepad' && data.status.startsWith('tetris') && tetris) {
                console.log('tetris event', data.status);
                tetris.onEvent('button', { status: data.status.replace('tetris_', '') });
            }
        },
        levelUp(level) {
            this.$store.direct.dispatch.rooms.setLevel({ roomid: this.roomid, level });
        },
        runningChanged(running) {
            this.running = running;
        }
    }
});
