import { defineComponent } from 'vue';
import { mapValues, pickBy } from 'lodash';
import './TeamScore.scss';
import useRooms from '@/compositions/useRooms';
export default defineComponent({
    name: 'TeamScore',
    props: {
        team: {
            type: Object,
            required: true
        }
    },
    setup: () => ({
        ...useRooms()
    }),
    computed: {
        roomNames() {
            return mapValues(this.rooms, room => room.name);
        },
        scoreFactor() {
            return mapValues(this.rooms, room => { var _a; return (_a = room.weight) !== null && _a !== void 0 ? _a : 1; });
        },
        scores() {
            if (this.team.games === undefined)
                return {};
            const scores = {};
            this.team.games.forEach(game => {
                var _a, _b;
                const room = game.room;
                const factor = (_a = this.scoreFactor[room]) !== null && _a !== void 0 ? _a : 1;
                const score = (game.level + 1) * factor;
                if (score > 0 && ((_b = scores[room]) !== null && _b !== void 0 ? _b : 0 < score)) {
                    scores[room] = Math.round(score);
                }
            });
            return pickBy(scores, (_, key) => !['score'].includes(key));
        },
        levels() {
            if (this.team.games === undefined)
                return {};
            const scores = {};
            this.team.games.forEach(game => {
                var _a;
                const room = game.room;
                const level = game.level;
                if ((_a = scores[room]) !== null && _a !== void 0 ? _a : 0 < level) {
                    scores[room] = level + 1;
                }
            });
            return pickBy(scores, (_, key) => !['score'].includes(key));
        },
        totalScore() {
            return Object.values(this.scores).reduce((total, score) => score + total, 0);
        }
    },
    methods: {
        getRoomName(roomId) {
            var _a;
            return (_a = this.roomNames[roomId.toLowerCase()]) !== null && _a !== void 0 ? _a : roomId;
        }
    }
});
