import { defineComponent } from 'vue';
import { TetrisGame } from '@/components/Rooms/Room/RoomArcade';
export default defineComponent({
    name: 'Tetris',
    components: {
        TetrisGame
    },
    mounted() {
        const game = this.$refs.game;
        const actions = {
            '4': 'left',
            '6': 'right',
            '2': 'down',
            '5': 'rotate',
            'a': 'left_2',
            'd': 'right_2',
            's': 'down_2',
            'w': 'rotate_2'
        };
        window.addEventListener("keypress", (e) => {
            console.log(`Key ${e.keyCode} has been pressed`);
            const char = String.fromCharCode(e.keyCode);
            const action = actions[char];
            if (action) {
                // @ts-ignore
                game.onEvent('button', { status: action });
            }
            if (char === 'p') {
                //  @ts-ignore
                game.tetris.saveBoard();
            }
        });
    }
});
