import store from '@/store';
import { computed } from 'vue';
const useMyTeam = () => {
    const team = computed(() => store.state.rooms.playingTeam);
    const teamName = computed(() => {
        if (team.value === null)
            return null;
        return team.value.name;
    });
    return {
        team,
        teamName
    };
};
export default useMyTeam;
