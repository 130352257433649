import store from "@/store";
import { onMounted, onUnmounted, computed } from "vue";
const useRFID = (rfidName) => {
    onMounted(async () => store.dispatch.rfid.subscribe(rfidName));
    onUnmounted(async () => store.dispatch.rfid.unsubscribe(rfidName));
    return {
        currentRFID: computed(() => { var _a; return (_a = store.state.rfid.RFID[rfidName]) !== null && _a !== void 0 ? _a : null; })
    };
};
export default useRFID;
