import { defineComponent } from 'vue';
import { Table } from '@/components/UI';
export default defineComponent({
    name: 'Testgame',
    components: {
        Table
    },
    data: () => ({
        fields: [
            { key: 'name', name: 'Name', sortable: true },
            { key: 'status', name: 'status', sortable: true },
            { key: 'type', name: 'Type', sortable: false }
        ],
        hasChanged: []
    }),
    computed: {
        sensors() {
            const blacklist = new Set(['led', 'ledstrip']);
            const status = Object.values(this.$store.direct.state.rooms.status)[0];
            if (status === undefined)
                return [];
            const flattenedSensors = [];
            status.sensors.forEach(sensor => {
                if (sensor.type === 'spidersensor') {
                    JSON.parse(sensor.status).holes.forEach((hole, index) => {
                        flattenedSensors.push({
                            name: `${sensor.name}_inside_motion_${index}`,
                            status: hole.laser,
                            type: 'Button'
                        });
                        flattenedSensors.push({
                            name: `${sensor.name}_side_${index}`,
                            status: hole.sides_touched,
                            type: 'Button'
                        });
                    });
                }
                else {
                    flattenedSensors.push(sensor);
                }
            });
            return flattenedSensors.filter(sensor => !blacklist.has(sensor.type) && !this.hasChanged.includes(sensor.name));
        }
    },
    watch: {
        sensors(newValue, oldValue) {
            newValue.forEach(sensor => {
                const oldValueSensor = oldValue.find(oldsensor => oldsensor.name === sensor.name);
                if (oldValueSensor !== undefined && oldValueSensor.status !== sensor.status) {
                    this.hasChanged.push(sensor.name);
                    new Audio(require('@/assets/sound/correct.mp3')).play();
                }
            });
        }
    }
});
