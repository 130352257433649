import TeamScore from '@/components/Teams/TeamScore';
import useClock from '@/compositions/useClock';
import useRFID from '@/compositions/useRFID';
import { defineComponent, computed } from 'vue';
import '@/sass/transitions.scss';
//import { Team, TeamGameType, TeamStatus } from '@/types/teams'
//import { BeatoutLanguage } from '@/types/translations'
import TeamGraph from '@/components/Teams/TeamGraph';
export default defineComponent({
    name: 'ScorePage',
    components: {
        TeamScore,
        TeamGraph
    },
    // eslint-disable-next-line max-lines-per-function
    setup() {
        const { currentRFID } = useRFID('counterrfid');
        const { now } = useClock();
        const lastChecked = computed(() => {
            var _a, _b;
            return (_b = (_a = currentRFID.value) === null || _a === void 0 ? void 0 : _a.time) !== null && _b !== void 0 ? _b : 0;
        });
        // eslint-disable-next-line max-lines-per-function
        const currentTeam = computed(() => {
            var _a, _b;
            /*const team: Team =
            {
              name: 'scoretest',
              members: [],
              duration: 7200,
              game_type: TeamGameType.Beatout,
              team_level: 0,
              _id: '',
              id: '',
              start_time: null,
              pin: 0,
              score: 0,
              state: TeamStatus.Waiting,
              scenario: null,
              language: BeatoutLanguage.English,
              schedule: [],
              games: [
                {room: 'lasermaze', level : 6},
                {room: 'birdsroom', level : 42},
                {room: 'reactionroom', level : 12},
                {room: 'coderoom', level : 5},
                {room: 'phoneroom', level : 2},
                {room: 'searchroom', level : 3}
              ]
            }
            return team*/
            if (currentRFID.value === null)
                return null;
            if (lastChecked.value < now.value - 25)
                return null;
            return (_b = (_a = currentRFID.value.used) === null || _a === void 0 ? void 0 : _a.finished) !== null && _b !== void 0 ? _b : null;
        });
        return {
            lastChecked,
            currentTeam
        };
    },
    data: () => ({
        countdownvisible: true
    }),
    watch: {
        currentTeam: {
            handler(current, previous) {
                console.log("Watch currentTeam");
                if (current === null) {
                    this.countdownvisible = true;
                }
                else if (previous === undefined || previous === null || current.name != previous.name) {
                    this.countdownvisible = true;
                    console.log("Countdown");
                    setTimeout(() => { this.countdownvisible = false; }, 3000);
                }
            },
            immediate: true
        }
    }
});
