import { defineComponent } from 'vue';
import { Animation } from '@/Animation';
import { Timer } from '@/components/Widgets';
import Interval from '@/components/General/Interval';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'Pregame',
    components: {
        Timer,
        Interval
    },
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    computed: {
        timer() {
            return this.$store.direct.state.rooms.timer;
        }
    },
    methods: {
        update() {
            Animation.animateRemainingTime('#clocktimer .icon', () => { });
        }
    }
});
