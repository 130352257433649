import { CheckBox } from '@/components/UI';
import { defineComponent } from 'vue';
export default defineComponent({
    name: "RoomArcadeEdit",
    components: { CheckBox },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    methods: {
        addNewButtonPair() {
            this.config.buttons.push({ button: "", led: "", tutorial: false });
        },
        removeButtonPair(key) {
            this.config.buttons.splice(key, 1);
        }
    }
});
