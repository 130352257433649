import { Tetris, TetrisAction } from '@/classes/arcadeGames/tetris';
import { computed, defineComponent, ref, watch } from 'vue';
import TetrisBoard from './TetrisBoard.vue';
export default defineComponent({
    name: 'TetrisGame',
    components: {
        TetrisBoard
    },
    props: {
        levelInfo: {
            type: Object,
            required: true
        }
    },
    emits: {
        levelup: (level) => 0 <= level && level <= 10,
        runningChanged(_running) { return true; }
    },
    setup(props) {
        const tetris = ref(new Tetris());
        tetris.value.numBlocks = props.levelInfo.num_blocks;
        const level = ref(0);
        const running = ref(false);
        const gameOver = computed(() => tetris.value.gameOver);
        watch(gameOver, (val) => {
            if (val) {
                console.log('GAME OVER');
                running.value = false;
                tetris.value.reset();
            }
        });
        return {
            tetris,
            gameOver,
            running,
            level
        };
    },
    data: () => ({
        interval: null
    }),
    watch: {
        levelInfo(newVal, oldVal) {
            if (newVal.level === oldVal.level)
                return;
            this.tetris.reset();
            this.tetris.numBlocks = newVal.num_blocks;
            this.interval = setInterval(this.update, newVal.speed);
        },
        running(newVal) {
            this.$emit('runningChanged', newVal);
        }
    },
    mounted() {
        this.interval = setInterval(this.update, 1000);
        /* For debugging only, needs to go */
        const actions = {
            '4': 'left',
            '6': 'right',
            '2': 'down',
            '5': 'rotate',
            'a': 'left_2',
            'd': 'right_2',
            's': 'down_2',
            'w': 'rotate_2'
        };
        window.addEventListener("keypress", (e) => {
            console.log(`Key ${e.keyCode} has been pressed`);
            const char = String.fromCharCode(e.keyCode);
            const action = actions[char];
            if (action) {
                this.onEvent('button', { status: action });
            }
        });
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
    methods: {
        update() {
            if (!this.running)
                return;
            this.tetris.update();
            if (this.tetris.score.lines >= this.levelInfo.num_rows) {
                this.level += 1;
                this.$emit('levelup', this.level);
                this.running = false;
                if (this.interval) {
                    clearInterval(this.interval);
                }
            }
        },
        onEvent(eventType, data) {
            var _a;
            if (eventType === 'button') {
                this.running = true;
                const action = data.status.split('_')[0];
                const blockId = Number.parseInt((_a = data.status.split('_')[1]) !== null && _a !== void 0 ? _a : '1') - 1;
                if (action === 'left') {
                    this.tetris.performAction(TetrisAction.LEFT, blockId);
                }
                else if (action === 'right') {
                    this.tetris.performAction(TetrisAction.RIGHT, blockId);
                }
                else if (action === 'down') {
                    this.tetris.performAction(TetrisAction.DOWN, blockId);
                }
                else if (action === 'rotate') {
                    this.tetris.performAction(TetrisAction.ROTATE_LEFT, blockId);
                }
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
        }
    }
});
