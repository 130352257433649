import { TetrisBlock } from "./tetris/TetrisBlock";
import { TetrisBoard } from "./tetris/TetrisBoard";
export var TetrisAction;
(function (TetrisAction) {
    TetrisAction["LEFT"] = "LEFT";
    TetrisAction["RIGHT"] = "RIGHT";
    TetrisAction["DOWN"] = "DOWN";
    TetrisAction["ROTATE_LEFT"] = "ROTATE_LEFT";
    TetrisAction["ROTATE_RIGHT"] = "ROTATE_RIGHT";
})(TetrisAction || (TetrisAction = {}));
export class Tetris {
    constructor() {
        this.blocks = [];
        this.__score = { lines: 0, points: 0 };
        this.running = true;
        this.board = new TetrisBoard(20, 10);
        this.blocks = [this.enterNewBlock(0)];
    }
    reset() {
        this.board = new TetrisBoard(20, 10);
        // make sure that the amount of blocks is the same as before by calling the getter and setter
        this.numBlocks = this.numBlocks;
        this.__score = { lines: 0, points: 0 };
        this.running = true;
    }
    get numBlocks() {
        return this.blocks.length > 0 ? this.blocks.length : 1;
    }
    set numBlocks(num) {
        if (num < 1 || num > 2)
            return;
        if (num === 1) {
            this.blocks = [this.enterNewBlock(0)];
        }
        else {
            this.blocks = [this.enterNewBlock(0), this.enterNewBlock(0)];
            this.blocks = [this.enterNewBlock(0), this.enterNewBlock(1)];
        }
    }
    get score() {
        return this.__score;
    }
    get rows() {
        return this.board.rows;
    }
    get cols() {
        return this.board.cols;
    }
    get nextBlock() {
        return this.board.nextBlock;
    }
    saveBoard() {
        console.log(JSON.stringify(this.board.matrix));
    }
    loadBoard(board) {
        this.board.matrix = board;
    }
    getColor(row, col) {
        var _a, _b, _c, _d;
        const colors = ['red', 'green'];
        for (const block of this.blocks) {
            const x = row - block.x;
            const y = col - block.y;
            if ((_a = block.block.shape[x]) === null || _a === void 0 ? void 0 : _a[y]) {
                return (_b = colors[0]) !== null && _b !== void 0 ? _b : 'red';
            }
            colors.shift();
        }
        return (_d = (_c = this.board.matrix[row]) === null || _c === void 0 ? void 0 : _c[col]) !== null && _d !== void 0 ? _d : null;
    }
    update() {
        if (!this.running)
            return;
        this.blocks.forEach((block, index) => {
            if (!this.board.hasCollision({ ...block, x: block.x + 1 })) {
                block.x++;
            }
            else {
                this.board.fixBlock(block);
                this.blocks[index] = this.enterNewBlock(index);
                const numLines = this.board.removeEmptyRows();
                this.__score.lines += numLines;
                this.__score.points += numLines * 100;
                if (numLines > 0)
                    new Audio(require('@/assets/sound/arcade-complete.mp3')).play();
                else
                    new Audio(require('@/assets/sound/arcade-done.mp3')).play();
            }
        });
    }
    enterNewBlock(blockNumber = 0) {
        const width = this.board.cols / this.numBlocks;
        const x = width * blockNumber;
        const state = {
            block: this.board.nextBlock,
            x: 0,
            y: Math.floor(x + width / 2) - 1
        };
        this.board.nextBlock = new TetrisBlock();
        this.running = !this.board.hasCollision(state);
        return state;
    }
    get gameOver() {
        return !this.running;
    }
    // eslint-disable-next-line max-lines-per-function
    performAction(action, index = 0) {
        const block = this.blocks[index];
        if (block === undefined)
            return;
        const actions = {
            [TetrisAction.DOWN]: () => {
                return { x: block.x + 1, y: block.y, block: block.block };
            },
            [TetrisAction.LEFT]: () => {
                return { x: block.x, y: block.y - 1, block: block.block };
            },
            [TetrisAction.RIGHT]: () => {
                return { x: block.x, y: block.y + 1, block: block.block };
            },
            [TetrisAction.ROTATE_LEFT]: () => {
                return { x: block.x, y: block.y, block: block.block.rotated() };
            },
            [TetrisAction.ROTATE_RIGHT]: () => {
                return { x: block.x, y: block.y - 1, block: block.block.rotated().rotated().rotated()
                };
            }
        };
        const newState = actions[action]();
        const otherBlocks = this.blocks.filter((_, i) => i !== index);
        if (!this.board.hasCollision(newState)
            && otherBlocks.every(block => !this.collisionBetweenBlocks(block, newState))) {
            this.blocks[index] = newState;
            if (action === TetrisAction.DOWN) {
                this.__score.points += 1;
            }
        }
    }
    blockAtLocation(block, row, col) {
        var _a;
        const x = row - block.x;
        const y = col - block.y;
        if ((_a = block.block.shape[x]) === null || _a === void 0 ? void 0 : _a[y])
            return true;
        return false;
    }
    collisionBetweenBlocks(block1, block2) {
        for (let i = 0; i < block1.block.shape.length; i++) {
            // @ts-ignore
            for (let j = 0; j < block1.block.shape[i].length; j++) {
                if (this.blockAtLocation(block1, block1.x + i, block1.y + j)
                    && this.blockAtLocation(block2, block1.x + i, block1.y + j)) {
                    return true;
                }
            }
        }
        return false;
    }
}
