import { TetrisBlock } from "./TetrisBlock";
export class TetrisBoard {
    constructor(lines, cols) {
        this.matrix = Array.from({ length: lines }, () => Array.from({ length: cols }, () => null));
        this.nextBlock = new TetrisBlock();
    }
    get rows() {
        return this.matrix.length;
    }
    get cols() {
        var _a, _b;
        return (_b = (_a = this.matrix[0]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
    }
    removeEmptyRows() {
        var _a;
        let numFullRows = 0;
        for (let i = this.rows - 1; i >= 0; i--) {
            if ((_a = this.matrix[i]) === null || _a === void 0 ? void 0 : _a.every(col => col !== null)) {
                this.matrix.splice(i, 1);
                this.matrix.unshift(Array.from({ length: this.cols }, () => null));
                i++;
                numFullRows++;
            }
        }
        return numFullRows;
    }
    fixBlock(block) {
        block.block.shape.forEach((row, x) => {
            row.forEach((col, y) => {
                if (col) {
                    this.setColor(block.x + x, block.y + y, 'blue');
                }
            });
        });
    }
    setColor(row, col, color) {
        var _a;
        if (((_a = this.matrix[row]) === null || _a === void 0 ? void 0 : _a[col]) !== undefined) {
            // @ts-ignore
            this.matrix[row][col] = color;
        }
        else {
            console.log('Trying to edit a non-empty field', row, col);
        }
    }
    hasCollision(block) {
        return block.block.shape.some((row, x) => {
            return row.some((col, y) => {
                var _a, _b;
                if (col &&
                    (((_a = this.matrix[block.x + x]) === null || _a === void 0 ? void 0 : _a[block.y + y]) !== null ||
                        ((_b = this.matrix[block.x + x]) === null || _b === void 0 ? void 0 : _b[block.y + y]) === undefined)) {
                    return true;
                }
                return false;
            });
        });
    }
}
